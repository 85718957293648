/* src/components/common/Hero.css */


/* 
Colour Scheme

Orange:
  color: rgb(205, 104, 76);
Blue:
  color: rgba(68, 153, 177);
Gray:
  color: rgba(128, 128, 128);
  

Font Style
font-family: 'Julius Sans One';

 */


.hero-container {
  position: inherit;

  /* Banner starting bellow navbar */
  margin-top: 100px;
  height: calc(90vh - 100px);
  
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;

}

.hero-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  height: 100%;
  /* background: url('../../assets/images/writing-hand-rehabilitation-zoom.png') center/cover no-repeat; */
  filter: blur(4px);
  z-index: -1; /* Place it behind the content */
}

.hero-content {
  text-align: left;
  width:90%;
  color: rgba(128, 128, 128);
  position: relative; /* Ensure the content is in front of the pseudo-element */
  z-index: 0; /* Ensure the content is in front of the pseudo-element */
  /* max-width: 60%; */
  padding-bottom: 5vh ;

  @media (max-width: 768px) {
    padding-bottom: 10vh ;


  }

}

.hero-headline {
  /* font-size: 4rem; */
  margin-bottom: 1rem  !important;
  font-weight: bold;
  text-shadow: 1px 0 rgba(128, 128, 128);
  

}

.hero-subheadline {
  font-size: 1.5rem;
  margin-bottom: 2rem !important;
  font-weight: bold;
  text-shadow: 1px 0 rgba(128, 128, 128);
  

}

.hero-button {
  padding: 1.2rem 2.4rem; /* Increase padding for a larger touch area */
  font-size: 1.2rem;
  background-color: rgba(68, 153, 177);
  color: #fff !important;
  border: none;
  cursor: pointer;
  font-family: 'Julius Sans One';
  transition: background-color 0.3s;
  display: inline-block; /* Ensure the link inside behaves like a button */

  /* bold font */
  font-weight: 900; 

  /* extra bold with shadow */
   /*
  text-shadow:
    1px 1px 0 #000,   /* Shadow to the right and down 
    -1px -1px 0 #000, /* Shadow to the left and up 
    1px -1px 0 #000,  /* Shadow to the right and up 
    -1px 1px 0 #000;  /* Shadow to the left and down 
    */
}


.hero-button a {
  color: #fff !important;

}

.hero-button:hover {
  background-color: rgba(205, 104, 76);

}