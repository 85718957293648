/* src/components/common/PrivacyPolicy.css */


/* 
Colour Scheme

Orange:
  color: rgb(205, 104, 76);
Blue:
  color: rgba(68, 153, 177);
Gray:
  color: rgba(128, 128, 128);
  

Font Style
font-family: 'Julius Sans One';

 */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the overlay is above other elements */
}

.modal-content {
  background-color: white;
  color: rgba(128, 128, 128);
  font-weight:bold ; /* Make the text bold */


  padding: 20px;
  /* border-radius: 8px; */
  max-width: 70%; /* Adjust as needed for the width */
  max-height: 90%; /* Adjust as needed for the height */
  overflow-y: auto;
  margin: 20px auto; /* Center the modal and add spacing from top and bottom */
  z-index: 10000; /* Ensure the modal content is above the overlay */
  position: relative; /* Ensure the z-index takes effect */
}

.modal-content p {
  display: inline !important;

}

.modal-content h3,.modal-content h2 {
  color: rgb(205, 104, 76);
  font-weight:bold ; /* Make the text bold */

}



@media (max-width: 768px) {
  .p {
    display: flex;
    flex-direction: column !important; /* Stack the columns vertically */
  }

  .p {
    width: 100%; /* Make each column take up the full width */
    margin-bottom: 20px; /* Add space between stacked columns */
  }
}


.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 2.5em;
  cursor: pointer;
  color: rgba(128, 128, 128);

}

.close-policy-button {
  margin-top: auto; /* This will push the button to the bottom */ 
  margin-left: auto; /* Center the button horizontally */
  margin-right: auto; /* Center the button horizontally */
  /* don't add bottom margin */
  width: fit-content;
  background-color: rgba(68, 153, 177);
  color: #fff;
  padding: 8px 16px;
  border: none;
  /* border-radius: 4px; */
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Julius Sans One';
}

.close-policy-button:hover {
  background-color: rgb(205, 104, 76);
}

/* src/components/common/PrivacyPolicy.css */
.privacy-link {
  color: rgb(205, 104, 76);
  cursor: pointer;
}

.privacy-link:hover {
  color: rgba(68, 153, 177);
  /* text-decoration: underline; */

}
