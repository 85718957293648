/* src/components/common/Footer.css */

#footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  
}

.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #fff;
  color: rgb(205, 104, 76);
  padding: 20px;
  padding-bottom: 0px !important;
  align-items: center;
  font-family: 'Julius Sans One';  
  max-width: 100%;
  
}

.bold {
  font-weight: bold;
}

#LogoAndSocial {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.social-icons .fa {
  padding: 5px;
  padding-top: 15px;
  font-size: 30px;
  width: 30px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  color: rgba(68, 153, 177);
}

.social-icons .fa:hover {
  color: rgb(205, 104, 76);
}

.footer-section {
  padding: 20px;

}

.footer-section img {
  max-height: 150px !important; /* Adjust the height as needed */
  width: auto !important;
}



#SitemapAndDetails {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

#SitemapAndDetails a {
  padding: 0px !important;
  margin-bottom: 10px;
  display: block; /* Added to ensure vertical spacing works */
}

.social-media {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.social-icons img {
  max-width: 30px;
  max-height: 30px;
}

a {
  text-decoration: none;
  color: rgb(205, 104, 76);
}

a:hover {
  color: rgba(68, 153, 177);
}

#CopyrightAndPrivacy {
margin-bottom: 10px;
}

#CopyrightAndPrivacy,
#CopyrightAndPrivacy a,
#CopyrightAndPrivacy p {
  width: 100%;
  display: flex;
  justify-content: space-around;
  font-size: x-small;
  padding: 0; /* Corrected padding value */
  margin: 0;
}


#Af img {
  width: 40px;
  padding: 0; /* Corrected padding value */
  margin-top: 10px;
  margin-bottom: 5px;
}

#Af p {
  margin-top: 0px !important;
  font-size: xx-small;
  color: rgba(128, 128, 128);
}

#Af:hover {
  text-decoration: none;
  opacity: 0.5;
  
  }

@media screen and (max-width: 768px) {
  .footer {
    flex-wrap: wrap;

  }

  #Af  {
    margin-top: 20px;
  }

  .footer-section {
    width: 80vw !important;
    padding: 10px;

  }

  .footer-sub-section {
    display: flex; /* Make it a flex container */
    flex-direction: row !important; /* Arrange flex items horizontally */
  }
  

  #LogoAndSocial {
    flex-wrap: wrap;
    justify-content: center !important;
    justify-self: center;
    padding: 5px;

  }

  #LogoAndSocial #logo:hover {
    opacity: 0.7 !important;  
  }

  #SitemapAndDetails {
    flex-wrap: wrap;
    justify-content: center;
  }

  #CopyrightAndPrivacy {
    flex-wrap: wrap;
    justify-content: center;
  }

  #CopyrightAndPrivacy p,
  #CopyrightAndPrivacy a {
    margin: 0px !important;
  }

  #CopyrightAndPrivacy a {
    padding-bottom: 5px !important;
  }

  /* #CopyrightAndPrivacy p {
    padding: 15px !important;
  }  */

  #CopyrightAndPrivacy .footer-section {
    padding: 15px !important;
  }

  #CopyrightAndPrivacy .footer-section.copyright {
    padding: 5px !important;
  }

}


