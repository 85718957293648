/* src/components/contact/Contact.css */

/* Styles for the Contact section */

/* 
Colour Scheme

Orange:
  color: rgb(205, 104, 76);
Blue:
  color: rgba(68, 153, 177);
Gray:
  color: rgba(128, 128, 128);
  
 */

 #contact {
  position:inherit;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: white;
   /* Changed background color to gray #818181 */
  /* padding-top: 3rem; */
  padding-bottom: 7rem;
  font-family: 'Julius Sans One';
  font-size: 1rem;
  text-align: left;

}


#padding{
  padding: 7rem 0;

}
/* #contact a,
#contact i{
    color: rgb(205, 104, 76) !important;

  &:hover{
    color: rgba(68, 153, 177) !important;
  }
} */

/* Additional styles for Contact section */

#contact-container {
  width: 90%;
  margin: 0 auto;
}

#contact-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  
}

#contact-right {
  flex: 1;
  display: flex;
  justify-content: flex-end; /* Changed to flex-end to push content to the right */
  min-width: 450px;
 
}


@media (max-width: 1024px) {
  #contact-right {

  justify-content:center !important; /* Change back to flex-start on smaller screens */
  min-width: 100% !important;
  margin: 30px 0;
}

}


#contact-left {
  flex: 1;
  min-width: 450px;
  justify-content: flex-start ; /* Change back */
  max-width: 1050px;

}


@media (max-width: 1024px) {
  #contact-left {

  min-width: 100% !important;
}

}

  @media (max-width: 768px) {
    #contact-left {
    display: flex;
    justify-content: center !important; /* Change back */
    max-width: 100% !important;
    min-width: 50% !important;


  }
}



