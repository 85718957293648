
/* 
Colour Scheme

Orange:
  color: rgb(205, 104, 76);
Blue:
  color: rgba(68, 153, 177);
Gray:
  color: rgba(128, 128, 128);

 */

 .App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body{
  overflow-x: hidden !important;
  font-family: 'Julius Sans One';
  max-width: 100% !important;


  
 
  }


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* 
===============================
            Cotainers
===============================
*/

.cotainer-max-width{
  max-width: 2560px;


  @media (max-width: 2560px) {
    max-width: 100% !important;
  }

 

}

.cotainer-1920-width{
  max-width: 1920px !important;

  @media (max-width: 1920px) {
    max-width: 100% !important;

  }

}



/* 
===============================
            Fonts
===============================
*/

.font-xx-large{
  font-size: clamp(43px, 7vw, 95px);
}

.font-x-large{
  font-size: clamp(34px, 5.5vw, 70px);
}

.font-large{
  font-size: clamp(28px, 4.5vw, 55px);
}

.font-medium-large{
  font-size: clamp(28px, 3.5vw, 33px);
}

.font-medium{
  font-size: clamp(20px, 3vw, 33px);
}

.font-medium-small{
  font-size: clamp(18px, 2.25vw, 24px);
}

.font-small-medium{
  font-size: clamp(16px, 1.75vw, 18px);
}

.font-small{
  font-size: clamp(15px, 1.25vw, 16px);
}


.font-x-small{
  font-size: clamp(12px, 1.25vw, 15px);
}

.font-xx-large, .font-x-large, .font-large,
.font-medium, .font-small, .font-x-small{
  margin: 0px;
  padding: 0px;
}





/* 
===============================
      Section Separator
===============================
*/

/* Custom section separator style */
.section-divider {
  position: relative;
  height: 1px;
  background-color: #ccc; /* Change the color as per your design */
  margin: calc(30px + 7%) 10vw; /* Adjust the margin as needed, this ajustment is to land perfectly with some offset the the sections headers */ 
  margin-bottom: 5% !important;

  @media screen and (max-width: 768px) {
    margin-bottom: 15% !important;
  }
}


/* Custom section separator style */
.section-divider-slim {
  position: relative;
  height: 1px;
  background-color: #ccc; /* Change the color as per your design */
  margin: 30px 10vw; /* Adjust the margin as needed, this ajustment is to land perfectly with some offset the the sections headers */ 
  margin-bottom: 5% !important;


}


.divider-icon {
  position: absolute;
  top: 0px; /* Adjust the positioning as needed */
  left: 50%;
  transform: translate(-50%, -50%); 
  background: url('../src/assets/images/Sunrise_therapy_sun.png') center/cover no-repeat;
  background-color: #fff;
  color: transparent;
  width: 15px; /* Set the desired width */
  height: 15px; /* Set the desired height */
  background-size: contain; /* Adjust as needed */
}



/* 
===============================
      Text Highlight
===============================
*/
/* text highlight config */
/* General styling for all browsers */
::selection {
  color: rgba(205, 104, 76);
  background: rgba(205, 104, 76, 0.3);
}

/* Firefox specific styling */
::-moz-selection {
  color: rgb(205, 104, 76);
  background: rgba(205, 104, 76, 0.3);
}

/* WebKit/Blink specific styling (Chrome, Safari) */
::-webkit-selection {
  color: rgba(205, 104, 76);
  background: rgba(205, 104, 76, 0.3);
}





