/* src/components/contact/Services.css */


/* 
Colour Scheme

Orange:
  color: rgb(205, 104, 76);
Blue:
  color: rgba(68, 153, 177);
Gray:
  color: rgba(128, 128, 128);
  

Font Style
font-family: 'Julius Sans One';

 */


.services-section {
  /* padding: 40px; */
  text-align: center;
  /* padding : 0 5%; */

}

.service-cards-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 50px;
}

.service-card {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 300px;
  padding: 20px;
  margin: 10px;
  border: 1px solid #ccc;
  /* border-radius: 8px; */
  transition: box-shadow 0.6s ease; /*default is 0,3*/
}

.service-card:hover {
  box-shadow: 0 0 15px rgba(68, 153, 177, 0.7);
}

.services-section h2,
.service-card h3,
.service-card p {
  /* color: #333; */
  color: rgba(128, 128, 128);

}

.service-card button, .see-all-button {
  margin-top: auto; /* This will push the button to the bottom */ 
  margin-left: auto; /* Center the button horizontally */
  margin-right: auto; /* Center the button horizontally */
  /* don't add bottom margin */
  width: fit-content;
  background-color: rgba(68, 153, 177);
  color: #fff;
  padding: 8px 16px;
  border: none;
  /* border-radius: 4px; */
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Julius Sans One';


    /* bold font */
    font-weight: 900; 

    /* extra bold with shadow */
     /*
    text-shadow:
      1px 1px 0 #000,   /* Shadow to the right and down 
      -1px -1px 0 #000, /* Shadow to the left and up 
      1px -1px 0 #000,  /* Shadow to the right and up 
      -1px 1px 0 #000;  /* Shadow to the left and down 
      */
}

.service-card button:hover {
  background-color: rgb(205, 104, 76);
}






.ot-services {
  padding: 20px;
  text-align: center;
  background-color: #f8f8f8;
  /* border-radius: 8px; */
  margin-top: 50px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 768px) {
    padding: 20px !important;


  }


}

.ot-services h3 {
  color: rgba(68, 153, 177);
  font-size: 24px;
}

.ot-services p {
  color: #333;
  margin-bottom: 20px;
}

/* .see-all-button {
  background-color: rgba(68, 153, 177);
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
} */

.see-all-button:hover {
  background-color: rgb(205, 104, 76);

 
}



