/* src/assets/styles/Navbar.css */
.navbar {
  z-index: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* padding: 10px; */
  background-color: #fff;
  color:  rgba(205, 104, 76);
  font-family: 'Julius Sans One';
  width:100%;
  position: fixed;
  left: 0;
  top:0;
  transition: background-color 0.6s ease, box-shadow 0.4s ease;/* Add a smooth transition */

}

.navbar.scrolled {
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  -moz-backdrop-filter: blur(4px);
  -ms-backdrop-filter: blur(4px);
  -o-backdrop-filter: blur(4px);
  
  /* Adjust background color for better visibility */
  background-color: rgba(255, 255, 255, 1); 

  /* the last number control transparency youtube color #818181;*/
	/* background-color: rgb(46, 54, 79, 0.3);   */

  /* depth shade effect */
  -webkit-box-shadow:rgba(0,0,0,0.8) 0px 5px 15px, inset rgba(0,0,0,0.15) 0px -5px 15px;
  -moz-box-shadow:rgba(0,0,0,0.8) 0px 5px 15px, inset rgba(0,0,0,0.15) 0px -5px 15px;
  -o-box-shadow:rgba(0,0,0,0.8) 0px 5px 15px, inset rgba(0,0,0,0.15) 0px -5px 15px;
  box-shadow:rgba(0,0,0,0.8) 0px 5px 15px, inset rgba(0,0,0,0.15) 0px -5px 15px;


  position: fixed;


  transition: 0.8s all ease;
}

.logo-container img {
  max-height: 80px !important; /* Adjust the height as needed */
  width: auto !important;
  padding: 10px;

}

.nav-links {
  display: flex;
  gap: 15px;
}

.nav-links a {
  text-decoration: none;
  color:  rgba(205, 104, 76);
  font-size: 16px;
  padding: 10px 8px; /* Add padding to increase the clickable area */

  transition: color 0.3s;
  font-weight: bold;
  
}

.nav-links a:hover {
  color: rgba(68, 153, 177);; /* Change color on hover */
}

.appointment-button button {
  background-color:  rgba(68, 153, 177);
  color: #fff;
  /* Recomended all touch targets must be 24px large, or leave sufficient space
old spacing:   padding: 10px 20px;

  */
  padding: 12px 24px;

  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  font-family: 'Julius Sans One';


    /* bold font */
    font-weight: 900; 

    /* extra bold with shadow */
     /*
    text-shadow:
      1px 1px 0 #000,   /* Shadow to the right and down 
      -1px -1px 0 #000, /* Shadow to the left and up 
      1px -1px 0 #000,  /* Shadow to the right and up 
      -1px 1px 0 #000;  /* Shadow to the left and down 
      */

}

.appointment-button button:hover {
  background-color:rgba(205, 104, 76); /* Change color on hover */
}



/* Add any additional responsive styling as needed */
@media screen and (max-width: 768px) {

  .navbar {
    justify-content: space-between;
  }

  .appointment-button button {
    display: none; /* Hide links on small screens initially */

  }
  
  .logo-container img {
    padding-left: 20px;
  }
 
  .logo-container img:hover {
    opacity: 0.7 !important;  
  }

  .nav-links {
    display: none; /* Hide links on small screens initially */
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    background-color: #253443;
    padding: 10px;
    text-align: center;
  }

  .nav-links.active {
    display: flex; /* Show links when the active class is present */
  }

  .nav-links a {
    font-size: 18px; /* Adjust font size for better visibility on small screens */
  }


  #menu:checked ~ .navbar .nav-links {
    display: flex;
  }
}
