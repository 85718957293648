/* src/components/about/About.css */

/* 
Colour Scheme

Orange:
  color: rgb(205, 104, 76);
Blue:
  color: rgba(68, 153, 177);
Grey:
  color: rgba(128, 128, 128);
  

Font Style
font-family: 'Julius Sans One';

 */

#about {
  display: flex;
  flex-direction: column;
  align-items: center; /* Align content center horizontally */
  justify-content: center !important;  
  align-items: center !important;
  color: rgba(128, 128, 128);
  /* padding : 5% 0; */
  font-weight:bold ; /* Make the text bold */
  overflow: hidden;
  width: 100%; 


  @media screen and (max-width: 768px) {
    width: 100%; 
  }
  
}

.subsection {
  display: flex;
  flex-direction: column;
  justify-content: center !important;  
  align-items: center;
  width: 90%;
  text-align: left;
  padding-top: 2.5%;
  
  /* Limiting maximum width for better readability */
  margin-bottom: 20px; /* Add some space between subsections */

  @media screen and (max-width: 768px) {
    padding-top: 5%;
  }
}


.subsection-grey-box {
  /* padding: 40px; */
  background-color: #f8f8f8;
  /* border-radius: 8px; */
  margin-top: 50px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center !important;  
  align-items: center !important;
  width: 100%;
  text-align: left;
  padding-top: 2.5%;
  
  /* Limiting maximum width for better readability */
  margin-bottom: 20px; /* Add some space between subsections */

  @media screen and (max-width: 768px) {
    padding: 0%;
  }
}



.text{

  max-width: 1150px;
  justify-content: center !important;  
  align-items: center !important;
    width: 90%;


  @media screen and (max-width: 768px) {
    width: 90%;
  }

}

.bio-container {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap */
}

.bio-item {
  display: flex;
  justify-content: center !important;  
  align-items: center; /* Align content center horizontally */
  width: calc(100%); /* Each bio-item takes full width */
   /* Add some padding */
  padding: 10px 0px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  position: relative; /* Required for the zoom effect */

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
}

.bio-item h2, .subsection h2{
  font-weight:bold ; /* Make the text bold */
  text-shadow: 1px 0 rgba(128, 128, 128);




  @media screen and (max-width: 768px) {
    text-align: center;

  }
}



.reverse{
  flex-direction: row-reverse; /* reverse order of text and img */
}

/* Apply fade-in effect when bio-item is in view */
.bio-item.show {
  opacity: 1;
}

/* Ensure images take up 1/3 of the width */
.bio-item img {
  width: calc(100%);  /* Ensure image is fluid */
  height: auto; /* Maintain aspect ratio */
  max-width: 350px;
  overflow: hidden; /* Ensure overflow is hidden for circular effect */
  transition: transform 0.3s; /* Add transition for smooth zoom effect */
  transform: scale(1.05); /* Zoom effect on hover */


  @media screen and (max-width: 768px) {
    min-width: 200px;
  }
}

.bio-img {
  background-color: rgb(205, 104, 76);
  border-radius: 50%; /* Make the image circular */
  width: calc(100% / 3);  /* Ensure image is fluid */
  max-width: 350px;
  overflow: hidden; /* Ensure overflow is hidden for circular effect */
  margin: 20px;

  @media screen and (max-width: 768px) {
    width: calc(100%);  /* Ensure image is fluid */
  }
}

.bio-img img {
  max-width: 100%; /* Ensure image is fluid within the circular container */
  transition: transform 0.3s; /* Add transition for smooth zoom effect */
}

.bio-img:hover img {
  transform: scale(1.1); /* Zoom effect on hover */
}

.bio-text {
  width: calc(100% / 2);  /* Ensure image is fluid */
  /* Add padding to text */
  /* padding: 10px;  */
  max-width: 800px; /* Set a suitable max-width for the text container */

  /* Each bio-item takes 1/3 of the width */
  @media screen and (max-width: 768px) {
    width: 100%;
 
  }
}

/* Ensure text takes up full width */
.bio-item p, .bio-item h2 {
  width: 100%; /* Make sure text takes full width */
}
