/* src/components/contact/ContactForm.css */

/* Styles for the ContactForm component */


/* 
Colour Scheme

Orange:
  color: rgb(205, 104, 76);
Blue:
  color: rgba(68, 153, 177);
Gray:
  color: rgba(128, 128, 128);

 */


 form {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  color: rgb(205, 104, 76);
}



@media (min-width: 1024px) {
  form {
  /* width: 45vw !important; */
}
}

label {
  line-height: 35px;
  color: rgb(205, 104, 76);

}

input {
  width: 90vw;
  height: 40px;
  font-size: 1rem;
  color: rgb(205, 104, 76) ;
  background-color: #fff !important;
  border-color: #ccc!important;
  border: 1px solid ;
  outline: none !important;
  font-family: 'Julius Sans One';

}


@media (min-width: 1024px) {
  input {
  width: 37.5vw !important;
  max-width: 1150px;
  }
}


input:focus {
  border-color: rgba(68, 153, 177) !important;
  box-shadow: 0 0 5px rgba(68, 153, 177) !important;
}

textarea {
  width: 90vw;
  height: 75px;
  color: rgb(205, 104, 76) ;
  font-size: 1rem;
  background-color: #fff !important;
  border-color: #ccc!important;
  border: 1px solid ;
  outline: none;
  font-family: 'Julius Sans One';

  /* width:max-content; */

}


@media (min-width: 1024px) {
  textarea {
  width: 37.5vw !important;
  max-width: 1150px;
}
}


textarea:focus {
  border-color: rgba(68, 153, 177) !important;
  box-shadow: 0 0 5px rgba(68, 153, 177) !important;
}

.error-message {
  color: red;
  font-size: 0.9rem;
}


#recaptcha-google{
  padding: 10px 0px;
}

#form .appointment-button  {
  max-width: 100vw !important;
  background-color:  rgba(68, 153, 177);
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  font-family: 'Julius Sans One';


    /* bold font */
    font-weight: 900; 

    /* extra bold with shadow */
     /*
    text-shadow:
      1px 1px 0 #000,   /* Shadow to the right and down 
      -1px -1px 0 #000, /* Shadow to the left and up 
      1px -1px 0 #000,  /* Shadow to the right and up 
      -1px 1px 0 #000;  /* Shadow to the left and down 
      */
}


@media (min-width: 1024px) {
  #form .appointment-button  {

  /* width: 37.5vw !important; */
  max-width: 1150px;
}
}


#form .appointment-button:hover {
  background-color:rgba(205, 104, 76); /* Change color on hover */
}
/* Add additional styles as needed */
